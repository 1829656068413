.header {
    position: relative;
    width: 100%;
    background-color: #fff;
  }
  
  .logo-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .hamburger {
    display: none; /* Hidden by default */
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    left: 0;
    color: #fff;
    PADDING-LEFT: 24PX;
  }
  
  .main-navigation {
    display: flex;
    gap: 20px;
  }
  
  .main-navigation a {
    text-decoration: none;
    color: #000;
    @media screen and (min-width: 600px) {

        color: #fff;
        
    }
  }
  
  @media (max-width: 600px) {
    .hamburger {
      display: block; /* Show hamburger on small screens (600px or less) */
    }
  
    .main-navigation {
      display: none; /* Hide the navigation by default on small screens */
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      background-color: white;
      width: 200px;
      padding: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  
    .main-navigation.open {
      display: flex; /* Show the navigation when the menu is open */
    }
  }
  