@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

:root {
  --primary-color: #0d4a64;
  --secondary-color: #f0f1f1;
  --accent-color: #c7dd75;
  --text-color: #1b5e5b;
  --white: #ffffff;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  color: var(--text-color);
}

.app {
  position: relative;
}

.header {
  position: relative;
  z-index: 1;
}

.header-content {
  position: relative;
  min-height: 720px;
  display: flex;
  align-items: center;
  padding:0px 200px 0px;
  @media screen and (max-width: 600px) {
    min-height: 0px;
    display: block;
    
  }
}

.header-background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-text {
  position: relative;
  z-index: 1;
  color: var(--white);
  margin-top: 250px;
  @media screen and (max-width: 600px) {
    margin-top: 0px;
    text-align: start;
    align-items: center;

    
  }
}

.header-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 65.37px;
  margin:0;
  
  font-family: 'Open Sans', sans-serif;
  @media screen and (max-width: 600px) {
 
    font-family: Open Sans;
font-size: 24px;
font-weight: 700;
line-height: 43.58px;
text-align: left;

  
    font-family: 'Open Sans', sans-serif;
    
  }
}

.header-subtitle {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.24px;
  line-height: 32.68px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 0px;
  @media screen and (max-width: 600px) {
  
    font-family: 'Open Sans', sans-serif;
 
font-size: 18px !important;
font-weight: 400;
line-height: 24.51px;
letter-spacing: -0.01em;
text-align: left;


    
  }
}

.cta-button {
  width: 236px;
  height: 53px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  
  color: #0C3343;
 
  padding: 16px 48px;
 
  cursor: pointer;
 
  line-height: 20px; 
  border-radius: 4px;
 
  font-family: 'Inter', sans-serif;

}

.logo-nav-container {
  background-color: rgba(13, 74, 100, 0.65);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 80px;
  @media screen and (max-width: 600px) {
    align-items: center;
    
  }
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  width:100px;
  height: 30px;
  /* margin-right: 20px;
  height: 30px; */

}

.main-navigation {
  display: flex;
}

.nav-link {
  color: var(--white);
  text-decoration: none;
  font-weight: 400;
  text-transform: capitalize;
  
 
  font-size: 18px;
  line-height: 24px;
  margin-left: 40px;
  font-family: 'Open Sans', sans-serif;
;
}

.vision-mission,
.approach,
.focus-area,
.intake-process
{
  padding: 93px 200px;
}

.section-title {
  font-size: 34px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 32px;
}

.section-description {
  font-size: 24px;
  line-height: 1.67;
}

.approach {
  background-color: var(--primary-color);
  color: var(--white);
}

.approach-header {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.approach-title {
  font-size: 34px;
  font-weight: 700;
  margin-left: 20px;
}

.approach-steps {
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.approach-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--white);
  border-radius: 140px;
  padding: 10px 30px 10px 90px;
}

.step-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.step-icon {
  width: 80px;
  height: 80px;
}

.step-title {
  font-size: 24px;
  font-weight: 600;
}

.step-image {
  width: 125px;
  height: 125px;
}

.focus-area {
  position: relative;
  display: flex;
  align-items: center;
}

.focus-area-image {
  width: 50%;
  object-fit: cover;
}

.focus-area-content {
  width: 50%;
  padding: 93px 150px;
  background-color: var(--secondary-color);
}

.intake-process {
  background-color: var(--primary-color);
  color: var(--white);
}

.process-steps {
  display: flex;
  justify-content: space-between;
}

.process-step {
  width: 30%;
}

.step-image {
  width: 100%;
  margin-bottom: 20px;
}

.step-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.step-description {
  font-size: 20px;
  line-height: 1.5;
}

.step-link {
  color: var(--accent-color);
  text-decoration: underline;
}

.team-image {
  width: 100%;
  margin-top: 44px;
}

.footer {
  background-color: var(--white);
  padding-top: 100px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.cta-section {
  max-width: 400px;
}

.cta-title {
  font-size: 34px;
  font-weight: 700;
  color: var(--primary-color);
  text-transform: uppercase;
  line-height: 1.4;
  margin-bottom: 32px;
}

.contact-info,
.location-info {
  font-family: Poppins, sans-serif;
}

.contact-info h3,
.location-info h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 25px;
}

.contact-info p,
.location-info p {
  font-size: 16px;
  line-height: 1.875;
  color: var(--primary-color);
}

.social-link {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 700;
  margin-top: 10px;
}

.social-icon {
  margin-right: 10px;
}

.map-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.footer-strip {
  background-color: #012738;
  height: 101px;
  margin-top: 96px;
}

.background-image {
  position: absolute;
  right: 10px;
  top: 821px;
  width: 720px;
  height: 720px;
  z-index: -1;
}

@media (max-width: 991px) {
  .header-content,
  .vision-mission,
  .approach,
  .focus-area,
  .intake-process,
  .team {
    padding: 100px 24px 40px;
  }

  .logo-nav-container {
    padding: 24px 20px;
    flex-direction: column;
    
   
  }

  .main-navigation {
    margin-top: 20px;
  }

  .nav-link {
    margin-left: 0;
    margin-right: 20px;
  }

  .focus-area {
    flex-direction: column;
  }

  .focus-area-image,
  .focus-area-content {
    width: 100%;
  }

  .focus-area-content {
    padding: 50px 20px;
  }

  .process-steps {
    flex-direction: column;
  }

  .process-step {
    width: 100%;
    margin-bottom: 40px;
  }

  .footer-content {
    flex-direction: column;
  }

  .cta-section,
  .contact-info,
  .location-info {
    margin-bottom: 40px;
  }

  .background-image {
    display: none;
  }
}
.react-tel-input{
  margin-top: 16px;
}

.react-tel-input .form-control {
  border: 1px solid #ddd !important;
  border-radius: 4px;
  padding-left: 50px !important;
  font-size: 16px;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #ddd !important;
  border-radius: 4px;
}

.react-tel-input .selected-flag {
  height: 100%;

  background-color: #f1f1f1;
}

.react-tel-input .selected-flag .arrow {
  margin-top: 12px;
}

.react-tel-input .form-control:focus {
  border-color: #aaa !important;
}
